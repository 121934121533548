












import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InvoiceForm from '@/components/InvoiceForm.vue'

export default Vue.extend({
  components: { Nav, InvoiceForm },
  methods: {
    invoiceCreated() {
      this.$router.push({
        name: 'invoices',
      })
    },
  },
})
